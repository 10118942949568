<template>
  <div class="reviewtextlearning" @click="hightlight = null">
    <!-- 步骤条 -->
    <div class="leftnav">
      <step-secont :active="active"/>
    </div>
    <img :src="textlist[0].ygjztpdz" alt="" width="0" height="0">
    <audio ref="audio" muted controls :src="audioVoice === 'y' ? details.ysypdz : details.msypdz" style="width: 0px;height: 0px;position: absolute;top: 0;">您的浏览器不支持 audio 标签。</audio>
    <main>
      <p class="title"><span>{{ details.kwmc }}</span></p>
      <div class="originalText">
        <div class="wordbox">
          <div class="wordList learntext" style="left: 52%;" v-if="yworfy">
            <div v-for="(item,index) in newTextlist" :key="index" style="text-indent:0;">
              <el-popover
                v-for="(ele,idx) in item" :key="ele+idx"
                placement="bottom"
                :width="200"
                trigger="click"
                :content="textlist[ele.sy].jzyw"
              >
                <template #reference>
                  <p class="itemzm" :style="[ele.zm == ' ' ? 'width:10px;height:1px;' : '', ele.sy == hightlight ? 'color: #1765C9;' : '', idx===0 ? 'text-indent:1em;' : '']" @click="setcolor(ele.sy)"> {{ele.zm}} </p>
                </template>
              </el-popover>
              <div style="clear:both;"></div>
            </div>
          </div>
          <div class="wordList learntext" style="left: 53%;" v-else title="">
            <div v-for="(item,index) in newFylist" :key="index" style="text-indent:0;">
              <el-popover
                v-for="(ele,idx) in item" :key="ele+idx"
                placement="bottom"
                :width="200"
                trigger="click"
                :content="textlist[ele.sy].jznr"
              >
                <template #reference>
                  <p class="itemzm" style="font-style:normal;font-family:none;" :style="[ele.zm == ' ' ? 'width:10px;height:1px;' : '', ele.sy == hightlight ? 'color: #1765C9;' : '', idx===0 ? 'text-indent:2em;' : '']" @click="setcolor(ele.sy)"> {{ele.hz}} </p>
                </template>
              </el-popover>
              <div style="clear:both;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer3">
        <div class="div" @click="playOrPause">
          <img src="../../../assets/student/playicon.png" alt="">&nbsp;&nbsp;{{playing ? '暂 停':'播 放'}}
        </div>
        <div class="div">
          <img src="../../../assets/student/bolang.png" alt="">
          <img src="../../../assets/student/bolang.png" alt="">
          <div class="seetext" :class="yworfy ? 'seetextfy' : 'seetextyw'" @click="translateBtn">
            <img src="../../../assets/student/wordysxbtn.png" alt="">
            <p>{{yworfy ? '查看翻译' : '查看原文'}}</p>
          </div>
        </div>
        <div class="div" @click="seeword">查看生词和短语</div>
        <div class="div" @click="goFirstStep">拓展练习</div>
      </div>
    </main>
  </div>
  <Header :header_data="header_data" :noClicking="true" />
</template>
<script>
import Header from "../components/zjfheader.vue";
import { useStore } from 'vuex'
import { onMounted } from "vue-demi";
import { getCurrentInstance, reactive, toRefs, ref, nextTick, onBeforeUnmount } from "vue";
import { getTextContent, getEnglishText } from '@/api/student'
import { ElMessage } from "element-plus"
import StepSecont from '../components/stepSecont.vue';
export default {
  components: {
    Header,
    StepSecont
  },
  setup() {
    const store = useStore()
    const audio = ref(null);
    const { appContext } = getCurrentInstance();
    const that = appContext.config.globalProperties;
    const data = reactive({
      yworfy: true, // 原文，或者翻译
      textlist: [{}],
      newTextlist: [],
      newFylist: [],
      hightlight: null, // 句子高亮
      active: 1, // 左侧步骤条
      header_data: [],
      details: {}, // 课文详情
      audioVoice: '',
      playing: false, // 播放和暂停状态
      clickProtect: false, // 点击保护
    });
    // 设置点击字母所在句子的颜色
    const setcolor = (val) => {
      data.hightlight = val
    }
    // 图谱英语第一环节
    const goFirstStep = () => {
      that.$router.replace({ name: 'reviewpracticeTwoStep', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid} })
    }
    onMounted(() => {
      data.audioVoice = localStorage.getItem(`sound_${store.state.userInfo.realname}`) || 'y'
      // 获取课文发音
      getEnglishText({kwid: that.$route.query.kwid}).then(res => {
        if(res.success) {
          data.details = res.result
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc+" — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc+" — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
          nextTick(()=>{
            audio.value.play()
            setTimeout(() => {
              audio.value.muted = false
              if (!audio.value.paused) {
                data.playing = true
              }
            }, 10)
          })
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      // 获取课文内容
      getTextContent({kwid: that.$route.query.kwid}).then(res => {
        if(res.success) {
            if(res.result.length) {
            let subsectionArr = []
            res.result.forEach((item, index) => {
              if(item.sfds) {
                subsectionArr.push(index)
              }
            })
            let newlist = []
            let ywnewlist = []
            subsectionArr.forEach((item,index) => {
              // 从第二个段首开始
              if(index) {
                // 两个段首之间有几句话
                let num = subsectionArr[index] - subsectionArr[index - 1]
                let arr = []
                let ywarr = []
                // 把两个段首间的句子放在数组里
                for(let i = 0 ; i < num ; i++) {
                  let list = res.result[subsectionArr[index - 1] + i].jznr.split(' ')
                  list.forEach(e => {
                    arr.push({zm:e, sy:subsectionArr[index - 1] + i})
                  })
                  let ywlist = res.result[subsectionArr[index - 1] + i].jzyw.split(' ')
                  ywlist.forEach(e => {
                    ywarr.push({hz:e, sy:subsectionArr[index - 1] + i})
                  })
                }
                newlist.push(arr)
                ywnewlist.push(ywarr)
              }
            })
            // 如果最后一个段首不是res.result最后一条
            if (subsectionArr[subsectionArr.length] !== (res.result.length - 1)){
              let num = res.result.length - subsectionArr[subsectionArr.length - 1]
              let arr = []
              let ywarr = []
              // 把两个段首间的句子放在数组里
              for(let i = 0 ; i < num ; i++) {
                let list = res.result[subsectionArr[subsectionArr.length - 1] + i].jznr.split(' ')
                list.forEach(ele => {
                  arr.push({zm:ele, sy: subsectionArr[subsectionArr.length - 1] + i})
                })
                let ywlist = res.result[subsectionArr[subsectionArr.length - 1] + i].jzyw.split(' ')
                ywlist.forEach(ele => {
                  ywarr.push({hz:ele, sy: subsectionArr[subsectionArr.length - 1] + i})
                })
                // arr.push({nr:res.result[subsectionArr[subsectionArr.length - 1] + i], sy:subsectionArr[subsectionArr.length - 1] + i})
              }
              newlist.push(arr)
              ywnewlist.push(ywarr)
            }
            data.newTextlist = JSON.parse(JSON.stringify(newlist))
            data.newFylist = JSON.parse(JSON.stringify(ywnewlist))
            data.textlist = res.result
          }
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
    })
    // 查看生词短语
    const seeword = () => {
      that.$router.replace({ name: 'reviewwordlearning', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid } })
    }
    // 原文/翻译
    const translateBtn = () => {
      data.yworfy = !data.yworfy
    }
    // 播放/暂停
    const playOrPause = () => {
      if (data.clickProtect) {
        return false
      }
      data.clickProtect = true
      if (data.playing) {
        audio.value.pause()
      } else {
        audio.value.play()
      }
      data.playing = !data.playing
      data.clickProtect = false
    }
    // 销毁钩子
    onBeforeUnmount(() => {
      audio.value.pause()
    })
    return {
      ...toRefs(data),
      translateBtn,
      setcolor,
      seeword,
      goFirstStep,
      audio,
      playOrPause,
    };
  },
};
</script>
<style lang="scss" scoped>
.reviewtextlearning {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner2.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding-top: 3vw;
    .title {
      font-size: 1.8vw;
      color: #0972E7;
      text-align: center;
      line-height: 1.5vw;
      span {
        display: inline-block;
        position: relative;
        font-weight: 600;
        font-family: 'Times New Roman', Times, serif;
      }
      // span::before {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 0.2vw;
      //   background: rgba(249, 164, 80, 0.6);
      //   position: absolute;
      //   bottom: 0.1vw;
      // }
    }
    .footer {
      width: 12.96vw;
      height: 4.11vw;
      background: url("../../../assets/student/wordysxbtn.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
    }
    .footer2 {
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      div{
        width: 12.96vw;
        height: 4.11vw;
        background: url("../../../assets/student/wordysxbtn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      div:nth-child(1) {
        margin-right: 2vw;
      }
    }
    .footer3 {
      text-align: center;
      line-height: 4.11vw;
      font-size: 1.4vw;
      color: #fff;
      position: absolute;
      bottom: 1.5vw;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      .div {
        width: 12.96vw;
        height: 4.11vw;
        line-height: 4.11vw;
        background: url("../../../assets/student/wordysxbtn.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        margin-right: 2vw;
      }
      .div:nth-child(1) {
        img {
          vertical-align: middle;
          display: inline-block;
          width: 2vw;
          height: 2vw;
        }
      }
      .div:nth-child(2) {
        background: url("../../../assets/student/writebtn.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        img{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        img:nth-child(1) {
          left: 10%;
        }
        img:nth-child(2) {
          right: 10%;
        }
        .seetext {
          width: 9.218vw;
          height: 4.11vw;
          position: absolute;
          transition: 0.5s;
          img {
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
          }
          p {
            position: absolute;
            width: 100%;
            text-align: center;
          }
        }
        .seetextfy {
          left: 0;
        }
        .seetextyw {
          left: 30%;
        }
      }
    }
    .wordbox {
      width: 100%;
      height: 70%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      overflow-y: auto;
    }
    .wordList {
      width: 80%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(-50%, -50%);
      .wordtext {
        width: 50%;
        float: left;
        line-height: 4.5vw;
        cursor: pointer;
        position: relative;
        font-size: 2vw;
        z-index: 2;
        img{
          display: block;
          position: absolute;
          top: 1vw;
          left: -4vw;
          transform: rotate(-10deg);
          width: 2vw;
          height: 2vw;
        }
        p{
          width: 90%;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      span {
        position: absolute;
        top: 1vw;
        left: 2vw;
        display: block;
        width: 3vw;
        height: 3.5vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .itemzm{
        float: left;
        position: relative;
        cursor: pointer;
        font-family: 'Times New Roman', Times, serif;
        padding: 0 7px;
      }
    }
    .learntext {
      font-size: 1.6vw;
      line-height: 4vw;
    }
    .type2box {
      width: 54%;
      height: 100%;
      margin: auto;
      box-sizing: border-box;
      padding-top: 2vw;
      .type2title{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 1vw;
        span {
          font-size: 2.5vw;
          margin-right: 1vw;
          display: inline-block;
          margin-bottom: .4vw;
        }
        img{
          display: inline-block;
          width: 3.281vw;
          height: 3.281vw;
          vertical-align: middle;
        }
      }
      .boxitem {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5vw;
        .label{
          font-size: 1.7vw;
          color: #333333;
          // margin: auto;
          // float: left;
          width: 8.59vw;
          vertical-align: middle
        }
        .value{
          // float: left;
          width: calc(100% - 165px);
          font-size: 1.3vw;
          white-space: pre-wrap;
          vertical-align: middle;
          line-height: 2.5vw;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.reviewtextlearning {
  ::-webkit-scrollbar {
    width: .6vw;
    margin-right: .3vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
}
.el-popover.el-popper{
  border: 2px solid #2D86EA !important;
  padding: 10px !important;
}
.el-popper.is-light .el-popper__arrow::before {
  border-top: 2px solid #2D86EA;
  border-left: 2px solid #2D86EA;
}
</style>